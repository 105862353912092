// Settings
$cdn-host: 'https://cdn.outdoorvoices.com/shopify/2.0.0/';

// Colors
$color-active: #ccd3da;
$color-black: #000;
$color-charcoal: #202020;
$color-gray-light: #d2d2d2;
$color-gray-xlight: #f1f1f1;
$color-gray-xlight-alpha: rgba(32, 32, 32, .07);
$color-gray-dark: #737373;
$color-ov-blue: #000f9f;
$color-red: #dd000d;
$color-white: #fff;
$color-yellow: #fc3;

$color-border: #949494;

$color-block-bg: rgba($color-white, .95);

$color-disabled: $color-gray-light;
$color-hover: $color-ov-blue;

// Fonts
$font-family-serif: 'merlo', georgia, serif;
$font-family-serif-italic: 'merlo-italic', georgia, serif;
$font-family-sans-serif: 'ov-gothic', 'HelveticaNeue-Bold', 'Helvetica Neue Bold', 'Helvetica Neue', arial, sans-serif;

$font-size-xxl: 48px;
$font-size-xl: 36px;
$font-size-lg: 24px;
$font-size-md: 18px;
$font-size-smd: 17px;
$font-size-sm: 16px;
$font-size-xsm: 14px;
$font-size-xxsm: 13px;

// Dimensions
$max-width: 1656px;
$nav-height: 72px;

// Breakpoints
$break-tablet: 1420px;
$break-tablet-portrait: 1023px;
$break-mobile-large: 960px;
$break-mobile: 575px;
$break-mobile-small: 375px;
// Column Breakpoints
$break-three-column: $break-tablet;
$break-two-column: $break-mobile-large;
$break-one-column: 550px;

// Easing functions
$ease-in-out: ease-in-out;
$ease-default: ease-in-out;
$ease-in-out-quad: cubic-bezier(.455, .03, .515, .955);
$ease-out-quart: cubic-bezier(.165, .84, .44, 1);
$ease-in-out-quart: cubic-bezier(.77, 0, .175, 1);
$ease-timing-fastest: .08s;
$ease-timing-fast: .1s;
$ease-timing-medium: .15s;
$ease-timing-slow: .2s;
$ease-timing-slowest: .8s;

$z-header: 1;
$z-alert: 5;
$z-alert-bg: 4;
$z-index-mobile-nav: 3;
$z-index-mobile-nav-bg: 2;
$z-nav-open: 30;
$z-nav: 3;
