@import '../../scss/config';

.root {
  align-items: center;
  background: $color-white;
  border-bottom: solid 1px transparent;
  display: flex;
  flex-shrink: 0;
  height: $nav-height;
  justify-content: center;
  position: sticky;
  top: 0;
  transition: border-bottom .1s ease-in-out;
  width: 100%;
  z-index: $z-header;
}

.inner {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: $max-width;
  width: 100%;
}

.scrolled {
  border-bottom: solid 1px $color-gray-xlight;
}

.logo {
  display: block;
  fill: currentColor;
  height: 16px;
  margin: 0 auto;

  &:hover {
    color: $color-ov-blue;
  }

  svg {
    display: block;
    height: 100%;
  }
}
